import React, { useState } from "react"
import { Spinner } from "react-bootstrap"
import contact from "../../assets/images/contact.png"
import ResultModal from "../../components/App/ResultModal"

const Sil = () => {
  const successMessage = "Form başarıyla gönderilmiştir."

  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState(successMessage)

  return (
    <div>
      <section className="contact-area pb-100 pt-100">
        <ResultModal
          show={show}
          onHide={() => setShow(false)}
          message={message}
          isSuccess={isSuccess}
        />
        <div className="container">
          <div className="section-title">
            <h2>Hesap Silme Talebi Gönderin</h2>
          </div>

          <div className="row">
            <div className="col-lg-5 col-md-11">
              <div className="contact-image">
                <img src={contact} alt="contact" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="contact-form">
                <form
                  id="contactForm"
                  onSubmit={async e => {
                    e.preventDefault()

                    const invId = e.target.invId.value
                    var phone = e.target.phone.value

                    setIsLoading(true)

                    if (isNaN(invId)) {
                      setMessage(
                        "Müşteri numarası hatalıdır. Lütfen tekrar deneyin."
                      )
                      setIsSuccess(false)
                      setShow(true)
                      return
                    }

                    const response = await fetch(
                      "https://apizone.alisverislio.com/api/account/rewy/delete",
                      {
                        method: "POST",
                        body: JSON.stringify({
                          invId: invId,
                          phone: phone,
                        }),
                      }
                    )
                    console.log(response)

                    setIsLoading(false)
                    if (response.status === 200) {
                      setMessage(successMessage)
                      setIsSuccess(true)
                      setShow(true)
                    } else {
                      var json = await response.json()
                      setMessage(json.message)
                      setIsSuccess(false)
                      setShow(true)
                    }
                  }}
                >
                  <div className="row">
                    <div className="col-lg-12 col-md-6">
                      <div className="form-group">
                        <input
                          id="invId"
                          type="number"
                          name="invId"
                          className="form-control"
                          required
                          placeholder="Müşteri Numaranız"
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-6">
                      <div className="form-group">
                        <input
                          id="phone"
                          type="number"
                          name="phone"
                          className="form-control"
                          required
                          placeholder="Telefon numaranız"
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      {isLoading ? (
                        <Spinner />
                      ) : (
                        <button type="submit" className="default-btn">
                          <i className="flaticon-tick"></i>
                          Talep Gönder <span></span>
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Sil
